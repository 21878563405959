<template>
    <div id="accredit-login">
        <navbar title="授权登录"></navbar>
        <div class="accredit-content">
            <div class="login-img">
                <img src="../../assets/images/logo/logo_rounded.png" alt="logo">
            </div>
            <div class="input-row">
                <input type="number" v-model="phoneNum" placeholder="请输入手机号">
            </div>
            <div class="input-row verify">
                <input type="text" v-model="CodeNum" placeholder="请输入验证码">
                <el-button size="small" @click="getCode">
                    {{sendModel.btnMsg == null ? sendModel.countNum+'s后重新发送' : sendModel.btnMsg}}</el-button>
            </div>
            <div class="loginBtn" @click="login">注册 / 登录</div>
        </div>
    </div>
</template>

<script>
import navbar from "COMPS/accredit_navbar.vue";
import { sendLoginSms, sendUpdatePwdSms } from "../../api/api";
export default {
    data() {
        return {
            // token: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE1ODI2MzA5NzkzNzQsInBheWxvYWQiOiJ7XCJ1c2VyVHlwZVwiOlwic3RhZmZcIixcInVzZXJJZFwiOlwiMGRmODc0OWRhYjIzNDNjN2IxNGYyYjVhZDY1N2Y1ODVcIixcInVzZXJDb2RlXCI6XCJkYWRkeVwiLFwiYWNjb3VudFwiOlwiZGFkZHlcIixcImxhc3ROYW1lXCI6bnVsbCxcImZpcnN0TmFtZVwiOm51bGwsXCJsYXN0TmFtZVBpbnlpblwiOm51bGwsXCJmaXJzdE5hbWVQaW55aW5cIjpudWxsLFwibmFtZVwiOlwi57O757uf566h55CG5ZGYXCIsXCJuYW1lUGlueWluZ1wiOm51bGwsXCJ0ZWxBcmVhQ29kZVwiOm51bGwsXCJ0ZWxcIjpcIlwiLFwibmlja05hbWVcIjpudWxsLFwiaGVhZEltYWdlVXJsXCI6bnVsbCxcIndlY2hhdElkXCI6bnVsbCxcImJpbmRXZWNoYXRVc2VySWRcIjpudWxsLFwiYmluZFdlY2hhdFVzZXJOaWNrTmFtZVwiOm51bGwsXCJxcU51bVwiOm51bGwsXCJjb3VudHJ5XCI6bnVsbCxcInByb3ZpbmNlXCI6bnVsbCxcImNpdHlcIjpudWxsLFwiYXJlYVwiOm51bGwsXCJzdHJlZXRcIjpudWxsLFwiYWRkcmVzc1wiOm51bGwsXCJjZXJ0aWZpY2F0ZU5vXCI6bnVsbCxcImZyb250UGhvdG9JZFwiOm51bGwsXCJjb250cmFyeVBob3RvSWRcIjpudWxsLFwibGV2ZWxcIjpudWxsLFwiaXNDaGlsZFwiOm51bGwsXCJwYXJlbnRJZFwiOm51bGwsXCJzZXhcIjpcIjBcIixcImJpcnRoZGF0ZVwiOjE1MzYyNDk2MDAwMDAsXCJlZHVjYXRpb25cIjpcIlwiLFwiZW1haWxcIjpcIlwiLFwic3RhdGVcIjpcIm5vcm1hbFwiLFwiYXV0aENvZGVcIjpudWxsLFwiYXV0aFRpbWVcIjpudWxsLFwiYXV0aFRlcm1cIjpudWxsLFwidG9rZW5cIjpudWxsLFwid3hPcGVuSWRcIjpudWxsLFwid3hBZ2VuY3lJZFwiOm51bGwsXCJ3eEFnZW5jeUNvZGVcIjpudWxsLFwid3hBZ2VuY3lUeXBlXCI6bnVsbCxcInd4QWdlbmN5TGV2ZWxcIjpudWxsLFwiYnVzaW5lc3NDb250cm9sTWFwXCI6bnVsbH0ifQ.FMoGMolgc1nc6kUIFJ8VIr1VEhcCMAIg2-zWqso41ZM",
            sendModel: {
                countNum: 60,
                countFlag: false,
                intervalBtn: {},
                btnMsg: "获取验证码",
            },
            phoneNum: "",
            CodeNum: "",
        };
    },
    methods: {
        countDown() {
            this.sendModel.btnMsg = null;
            this.sendModel.countFlag = !this.sendModel.countFlag;
            this.sendModel.intervalBtn = setInterval(() => {
                if (this.sendModel.countNum <= 0) {
                    this.sendModel.btnMsg = "获取验证码";
                    clearInterval(this.sendModel.intervalBtn);
                    this.sendModel.countFlag = !this.sendModel.countFlag;
                    this.sendModel.countNum = 60;
                }
                this.sendModel.countNum--;
            }, 1000);
        },
        getCode() {
            if (
                this.phoneNum != "" &&
                /^1(3|4|5|7|8)\d{9}$/.test(this.phoneNum)
            ) {
                if (this.sendModel.countFlag == false) {
                    sendLoginSms(this.phoneNum, 86).then(() => {
                        this.$message({
                            type: "success",
                            message: "验证码发送成功",
                        });
                        this.countDown();
                    });
                }
            } else {
                this.$message({
                    type: "warning",
                    message: "请输入正确的手机号码",
                });
            }
        },
        login() {
            if (
                this.phoneNum == "" &&
                !/^1(3|4|5|7|8)\d{9}$/.test(this.phoneNum)
            ) {
                this.$message({
                    type: "warning",
                    message: "请输入正确的手机号码",
                });
                return;
            }
            if (this.CodeNum == "") {
                this.$message({
                    type: "warning",
                    message: "验证码不能为空",
                });
                return;
            }

            sendUpdatePwdSms(this.phoneNum, this.CodeNum).then((data) => {
                console.log(data);
                this.$store.commit("saveUserData", data.data);
                // this.$store.commit("saveToken", data.data.token);
                sessionStorage.setItem("token",data.data.token);
                this.$message({
                    type: "success",
                    message: "登录成功",
                });
                this.$router.push({
                    path: "/accreditList",
                });
                this.countDown();
            });
        },
    },
    components: {
        navbar,
    },
};
</script>

<style scoped>
#accredit-login {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.accredit-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 20px;
}
.login-img {
    width: 100%;
    height: 288px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.login-img > img {
    width: 80px;
    height: 80px;
}
.input-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    border-bottom: 1px solid rgba(218, 218, 218, 1);
    padding-bottom: 10px;
}
.input-row > input {
    /* width: 100%; */
    border: none;
}
.verify {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
}
input:focus {
    outline: none;
}
.loginBtn {
    background: rgba(255, 96, 29, 1);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    color: white;
    font-size: 14px;
    height: 40px;
    margin-top: 45px;
    border-radius: 20px;
}
</style>